import { useSortLabel } from '@/composables'
import { computed, ref } from '@vue/composition-api'
import { planPassService } from '../services'

// import commonStore from '../store'

export default () => {
  const planPasses = ref([])

  const planPassOptions = computed(() =>
    planPasses.value
      .map(planPass => ({
        label: `${planPass.title.en} (${planPass.distributor.slug})`,
        value: planPass.id,
      }))
      .sort(useSortLabel),
  )

  const findPlanPass = id => planPasses.value.find(planPass => planPass.id === id)

  const basePlanPass = computed(() => planPasses.value.find(planPass => planPass.base))

  const fetchPlanPasses = query =>
    planPassService.index(query).then(({ records }) => {
      planPasses.value = records

      //   if (query.take >= 999) {
      //     commonStore.state.planPasses = records
      //   }

      return planPasses.value
    })

  // if (!planPasses.value.length) {
  //   fetchPlanPasses({ take: 999 })
  // }

  return {
    planPasses,
    planPassOptions,
    basePlanPass,

    findPlanPass,
    fetchPlanPasses,
  }
}
