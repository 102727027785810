<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <template #[`item.name`]="{ item }">
        {{ t(item.dictionary.value) }}
        <small v-if="item.dictionary.alias">
          | alias >> {{ t(item.dictionary.alias.value) }}
        </small>
        <v-chip
          v-for="note in item.notes"
          :key="note.id"
          class="mr-2"
          small
        >
          {{ t(note.content) }}
        </v-chip>
        <v-chip
          v-if="!item.active"
          color="error"
          text-color="white"
        >
          Inactive
        </v-chip>
      </template>

      <template #[`item.claim_plan_id`]="{ item }">
        {{ t(item.claim_plan.name) }}
      </template>

      <template #[`item.max_quantity`]="{ item }">
        {{ item.max_quantity }} * {{ item.max_total_per_quantity }} per {{ item.interval_count }} {{ item.interval_unit }}
        ({{ item.max_reimbursement_rate * 100 }}%)
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <edit-dialog
                v-if="$can('claim_plan:update', 'claim_plan_management')"
                :resource="item"
                @updated="paginate"
              />

              <v-list-item
                v-if="$can('claim_plan:update', 'claim_plan_management') && item.active"
                link
                @click="inactivateItem(item).then(paginate)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-3"
                  >
                    {{ icons.mdiPauseCircleOutline }}
                  </v-icon>
                  <span>Inactivate</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('claim_plan:update', 'claim_plan_management') && !item.active"
                link
                @click="activateItem(item).then(paginate)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-3"
                  >
                    {{ icons.mdiPlayCircleOutline }}
                  </v-icon>
                  <span>Activate</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('claim_plan:delete', 'claim_plan_management')"
                link
                @click="confirmDeleteItem(item).then(paginate)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-3"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import {
  mdiDeleteOutline, mdiDotsVertical, mdiPauseCircleOutline, mdiPlayCircleOutline,
} from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useClaimPlan from '../../composables/useClaimPlan'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const columns = [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Sort', value: 'sort' },
      { text: 'Granted to', value: 'granted_to' },
      { text: 'Plan', value: 'claim_plan_id' },
      { text: 'Max Budget', value: 'max_quantity' },
      { text: 'Cost', value: 'cost' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const { confirmDeleteItem, inactivateItem, activateItem } = useClaimPlan()

    return {
      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      confirmDeleteItem,
      inactivateItem,
      activateItem,

      icons: {
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPauseCircleOutline,
        mdiPlayCircleOutline,
      },
    }
  },
}
</script>
