var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.plan ? _c('v-card', [_c('div', {
    staticClass: "pl-4 pt-4"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'claim-plans'
      }
    }
  }, [_vm._v(" Back ")])], 1), _c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('span', {
    staticClass: "mr-4"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.plan.name)) + " ")]), _c('v-chip', {
    staticClass: "mr-4",
    attrs: {
      "color": _vm.plan.active ? 'success' : 'gray'
    }
  }, [_vm._v(" " + _vm._s(_vm.plan.active ? 'Active' : 'Inactive') + " ")]), _vm.plan.rule.max_item_per_claim === 1 ? _c('v-chip', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Single Claim ")]) : _vm._e()], 1), _c('div', [_c('EditDialog', {
    attrs: {
      "resource": _vm.plan
    },
    on: {
      "updated": _vm.reloadPlan
    }
  }, [_c('v-btn', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")]), _vm._v(" Edit Plan ")], 1)], 1)], 1)]), _c('v-card-text', [_c('p', [_vm._v(" The user can claim up to "), _c('b', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.maxQuantity) + " item times")]), _c('v-icon', [_vm._v(" " + _vm._s(_vm.plan.manifest.show_max_quantity ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff) + " ")]), _vm._v(" and "), _c('b', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.maxTotal) + " amount total")]), _c('v-icon', [_vm._v(" " + _vm._s(_vm.plan.manifest.show_max_total ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff) + " ")]), _vm._v(" from this plan. ")], 1), _c('p', [_vm._v(" The backend validates submitted: "), _vm.plan.rule.validate_quantity ? _c('b', {
    staticClass: "mr-2"
  }, [_vm._v("Quantity")]) : _vm._e(), _vm.plan.rule.validate_total ? _c('b', {
    staticClass: "mr-2"
  }, [_vm._v("Total")]) : _vm._e(), _vm.plan.rule.validate_owner_participant ? _c('b', {
    staticClass: "mr-2"
  }, [_vm._v("Participant in Owner")]) : _vm._e(), _c('b', {
    staticClass: "bb-hidden"
  }, [_vm._v("Nothing")])]), _c('p', [_vm._v(" Reward Points Lump Sum Gift: "), _c('b', [_vm._v(_vm._s(_vm.plan.reward_points))])]), _vm.plan.plan_pass ? _c('p', [_vm._v(" Associated Pass: "), _c('router-link', {
    attrs: {
      "to": {
        name: 'pass-list',
        query: {
          search: _vm.plan.plan_pass.title.en
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.plan.plan_pass.title.en) + " ")])], 1) : _vm._e(), _c('v-tabs', [_c('v-tab', [_vm._v(" Items ")]), _c('v-tab', [_vm._v(" Assign To User ")]), _c('v-tab', [_vm._v(" Documents ("), _c('span', [_vm._v(_vm._s(_vm.plan.documents.length))]), _vm._v(") ")]), _c('v-tab', [_vm._v(" Photos ("), _c('span', [_vm._v(_vm._s(_vm.plan.photos.length))]), _vm._v(") ")]), _c('v-tab-item', [_c('claim-plan-item-tab', {
    attrs: {
      "plan": _vm.plan
    }
  })], 1), _c('v-tab-item', [_c('user-plan-assignment-tab', {
    attrs: {
      "plan": _vm.plan
    }
  })], 1), _c('v-tab-item', [_c('user-plan-document-tab', {
    attrs: {
      "plan": _vm.plan
    },
    on: {
      "updated": _vm.reloadPlan
    }
  })], 1), _c('v-tab-item', [_c('user-plan-photo-tab', {
    attrs: {
      "plan": _vm.plan
    },
    on: {
      "updated": _vm.reloadPlan
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }