import { computed, ref } from '@vue/composition-api'
import { distributorSettingService } from '../services'

export default function useDistributorSetting() {
  const distributorSettings = ref([])

  const listDistributorSettings = (query = {}) =>
    distributorSettingService.index(query.distributor_id, query).then(data => {
      distributorSettings.value = data

      return data
    })

  const listByDistributor = (distributor, query = {}) =>
    listDistributorSettings({
      ...query,
      distributor_id: distributor.id,
    })

  const listByDistributorId = (id, query = {}) =>
    listDistributorSettings({
      ...query,
      distributor_id: id,
    })

  const financialSettings = computed(() => distributorSettings.value.filter(setting => setting.category === 'financial'))

  const financialDay = computed(() => financialSettings.value.find(setting => setting.key === 'financial_start_day')?.value || null)

  const financialMonth = computed(() => financialSettings.value.find(setting => setting.key === 'financial_start_month')?.value || null)

  return {
    distributorSettings,
    financialSettings,
    financialDay,
    financialMonth,
    listDistributorSettings,
    listByDistributor,
    listByDistributorId,
  }
}
