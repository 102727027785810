var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5 d-flex justify-space-between"
  }, [_vm._t("title", function () {
    return [_vm._v(" Claim Plan ")];
  }), _c('v-icon', {
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 2), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('translation-locales')], 1), !_vm.disabledFields.includes('distributor_id') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Distributor",
      "outlined": "",
      "rules": [_vm.validators.required],
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.fulfillmentOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Fulfillment method when approved",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('fulfillment')
    },
    model: {
      value: _vm.form.fulfillment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fulfillment", $$v);
      },
      expression: "form.fulfillment"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('translatable-input', {
    attrs: {
      "label": "Name",
      "disabled": _vm.disabledFields.includes('name')
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('translatable-editor', {
    attrs: {
      "label": "Description (Details)",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('description')
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.form.distributor_id ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Distributor financial start month: "), _c('b', [_vm._v(_vm._s(_vm.financialMonth))]), _vm._v(" "), _c('a', {
    on: {
      "click": function click($event) {
        return _vm.setDates(_vm.financialMonth, 'month');
      }
    }
  }, [_vm._v("SET")]), _vm._v("; day: "), _c('b', [_vm._v(_vm._s(_vm.financialDay))]), _vm._v(" "), _c('a', {
    on: {
      "click": function click($event) {
        return _vm.setDates(_vm.financialDay, 'day');
      }
    }
  }, [_vm._v("SET")])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("Effective Date: "), _c('b', [_vm._v(_vm._s(_vm.form.effective_date))])]), _c('v-date-picker', {
    model: {
      value: _vm.form.effective_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "effective_date", $$v);
      },
      expression: "form.effective_date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("Expires at: "), _c('b', [_vm._v(_vm._s(_vm.form.expires_at))]), _vm._v(" (" + _vm._s(_vm.now(_vm.form.expires_at).diff(_vm.now(_vm.form.effective_date), 'days')) + "Days)")]), _c('v-date-picker', {
    model: {
      value: _vm.form.expires_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expires_at", $$v);
      },
      expression: "form.expires_at"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Reward Points (Lump Sum Gift)",
      "prefix": _vm.baseCurrency.long_symbol,
      "type": "number",
      "outlined": ""
    },
    model: {
      value: _vm.form.reward_points,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reward_points", _vm._n($$v));
      },
      expression: "form.reward_points"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Rule")]), _c('p', [_vm._v("Control the claims that a participant can make "), _c('b', [_vm._v("for the entire plan")]), _vm._v(".")]), _c('p', [_vm._v("If the maximum limit for claims is set to 10, and the user has already claimed 9 items within the specified timeframe, they can make one final claim for any item available in the plan.")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Max Quantity (Quota)",
      "hint": " 0 = Unlimited. Each unique item in a claim is at least 1 quantity.",
      "type": "number",
      "outlined": "",
      "suffix": _vm.form.rule.max_quantity === 0 ? 'Unlimited' : '',
      "disabled": _vm.disabledFields.includes('rule.max_quantity')
    },
    model: {
      value: _vm.form.rule.max_quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "max_quantity", _vm._n($$v));
      },
      expression: "form.rule.max_quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Max Claim Plan lifetime Total",
      "hint": "0 = Unlimited. Maximum total a user can claim for the entire plan.",
      "type": "number",
      "prefix": _vm.baseCurrency.long_symbol,
      "suffix": _vm.form.rule.max_total === 0 ? 'Unlimited' : '',
      "outlined": "",
      "disabled": _vm.disabledFields.includes('rule.max_total')
    },
    model: {
      value: _vm.form.rule.max_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "max_total", _vm._n($$v));
      },
      expression: "form.rule.max_total"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Min Item per Claim",
      "hint": "Normally you just want to leave it '1'",
      "type": "number",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('rule.min_item_per_claim')
    },
    model: {
      value: _vm.form.rule.min_item_per_claim,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "min_item_per_claim", _vm._n($$v));
      },
      expression: "form.rule.min_item_per_claim"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Max Item per Claim",
      "hint": "1 is single item claim, !==1 is multiple",
      "type": "number",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('rule.max_item_per_claim')
    },
    model: {
      value: _vm.form.rule.max_item_per_claim,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "max_item_per_claim", _vm._n($$v));
      },
      expression: "form.rule.max_item_per_claim"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Validations before submit")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "label": "Plan&Item Quantity (Quota)"
    },
    model: {
      value: _vm.form.rule.validate_quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "validate_quantity", $$v);
      },
      expression: "form.rule.validate_quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "label": "Plan&Item Total/Balance"
    },
    model: {
      value: _vm.form.rule.validate_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "validate_total", $$v);
      },
      expression: "form.rule.validate_total"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "label": "participant is designated"
    },
    model: {
      value: _vm.form.rule.validate_owner_participant,
      callback: function callback($$v) {
        _vm.$set(_vm.form.rule, "validate_owner_participant", $$v);
      },
      expression: "form.rule.validate_owner_participant"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Manifest")]), _c('p', [_vm._v("Normally you just want to show either one.")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Show Max Quantity (Quota)",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('manifest.show_max_quantity')
    },
    model: {
      value: _vm.form.manifest.show_max_quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form.manifest, "show_max_quantity", $$v);
      },
      expression: "form.manifest.show_max_quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Show Max Claim Plan lifetime Total",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('manifest.show_max_total')
    },
    model: {
      value: _vm.form.manifest.show_max_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form.manifest, "show_max_total", $$v);
      },
      expression: "form.manifest.show_max_total"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Relationships ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.distributor_id ? _c('plan-pass-id-filter', {
    attrs: {
      "distributor-id": _vm.form.distributor_id
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Active",
      "outlined": "",
      "dsiabled": !_vm.resource
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }