var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "placeholder": "Select Country",
      "items": _vm.countryOptions,
      "item-text": "label",
      "item-value": "value",
      "single-line": "",
      "outlined": "",
      "clearable": "",
      "hide-details": _vm.hideDetails,
      "disabled": _vm.disabled,
      "dense": _vm.dense
    },
    model: {
      value: _vm.countryId,
      callback: function callback($$v) {
        _vm.countryId = $$v;
      },
      expression: "countryId"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }