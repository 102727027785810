var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "title",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Permissions Available ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.categories,
      "item-text": "title",
      "item-value": "value",
      "label": "Category",
      "placeholder": "Select category",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category,
      callback: function callback($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.category !== null,
      expression: "category !== null"
    }],
    staticClass: "d-inline-flex justify-start align-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "success me-5"
    },
    on: {
      "click": _vm.selectAll
    }
  }, [_vm._v(" Select All ")]), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.unselectAll
    }
  }, [_vm._v(" Unselect All ")])], 1)])], 1), _c('v-row', _vm._l(_vm.filteredPermisssions, function (permission, index) {
    var _permission$id;
    return _c('v-col', {
      key: permission.id,
      attrs: {
        "cols": "3"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "id": (_permission$id = permission.id) === null || _permission$id === void 0 ? void 0 : _permission$id.toString(),
        "value": permission.id.toString(),
        "label": _vm.t(permission.name_translation)
      },
      model: {
        value: _vm.selectedPermissions,
        callback: function callback($$v) {
          _vm.selectedPermissions = $$v;
        },
        expression: "selectedPermissions"
      }
    })], 1);
  }), 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  })], 1), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPermissions.length > 0,
      expression: "selectedPermissions.length > 0"
    }]
  }, [_c('v-col', {
    staticClass: "title",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Permissions Selected ")]), _c('v-col', {
    staticClass: "d-inline-flex flex-wrap",
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.selectedPermissions, function (permission) {
    return _c('v-chip', {
      staticClass: "mr-2 mb-2"
    }, [_vm._v(" " + _vm._s(_vm.t(_vm.getNameTranslationById(permission) || {
      en: 'Permission Not Found: ' + permission
    })) + " ")]);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }