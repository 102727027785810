import axios from '@axiosV2'

const index = (distId, params) =>
  axios.get(`/admin/distributors/${distId}/settings`, {
    params,
  })
const show = (distId, settingId) => axios.get(`/admin/distributors/${distId}/settings/${settingId}`)
const store = (distId, data) => axios.post(`/admin/distributors/${distId}/settings`, data)
const update = (distId, settingId, data) => axios.put(`/admin/distributors/${distId}/settings/${settingId}`, data)
const destroy = (distId, settingId) => axios.delete(`/admin/distributors/${distId}/settings/${settingId}`)

export default {
  destroy,
  show,
  index,
  store,
  update,
}
