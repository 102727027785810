<template>
  <div>
    <v-card v-if="plan">
      <div class="pl-4 pt-4">
        <router-link :to="{ name: 'claim-plans' }">
          Back
        </router-link>
      </div>
      <v-card-title class="d-flex justify-space-between">
        <div
          class="d-flex align-center"
          style="min-width: 300px;"
        >
          <span class="mr-4">
            {{ t(plan.name) }}
          </span>

          <v-chip
            :color="plan.active ? 'success' : 'gray'"
            class="mr-4"
          >
            {{ plan.active ? 'Active' : 'Inactive' }}
          </v-chip>

          <v-chip
            v-if="plan.rule.max_item_per_claim === 1"
            color="info"
          >
            Single Claim
          </v-chip>
        </div>

        <div>
          <EditDialog
            :resource="plan"
            @updated="reloadPlan"
          >
            <v-btn>
              <v-icon>
                {{ icons.mdiPencil }}
              </v-icon>
              Edit Plan
            </v-btn>
          </EditDialog>
        </div>
      </v-card-title>

      <v-card-text>
        <p>
          The user can claim up to
          <b class="mr-1">{{ maxQuantity }} item times</b>
          <v-icon>
            {{ plan.manifest.show_max_quantity ? icons.mdiEye : icons.mdiEyeOff }}
          </v-icon>
          and
          <b class="mr-1">{{ maxTotal }} amount total</b>
          <v-icon>
            {{ plan.manifest.show_max_total ? icons.mdiEye : icons.mdiEyeOff }}
          </v-icon>
          from this plan.
        </p>
        <p>
          The backend validates submitted:
          <b
            v-if="plan.rule.validate_quantity"
            class="mr-2"
          >Quantity</b>
          <b
            v-if="plan.rule.validate_total"
            class="mr-2"
          >Total</b>
          <b
            v-if="plan.rule.validate_owner_participant"
            class="mr-2"
          >Participant in Owner</b>
          <b class="bb-hidden">Nothing</b>
        </p>
        <p>
          Reward Points Lump Sum Gift: <b>{{ plan.reward_points }}</b>
        </p>
        <p v-if="plan.plan_pass">
          Associated Pass: <router-link :to="{ name: 'pass-list', query: { search: plan.plan_pass.title.en } }">
            {{ plan.plan_pass.title.en }}
          </router-link>
        </p>
        <v-tabs>
          <v-tab>
            Items
          </v-tab>
          <v-tab>
            Assign To User
          </v-tab>
          <v-tab>
            Documents (<span>{{ plan.documents.length }}</span>)
          </v-tab>
          <v-tab>
            Photos (<span>{{ plan.photos.length }}</span>)
          </v-tab>
          <v-tab-item>
            <claim-plan-item-tab :plan="plan" />
          </v-tab-item>
          <v-tab-item>
            <user-plan-assignment-tab :plan="plan" />
          </v-tab-item>
          <v-tab-item>
            <user-plan-document-tab
              :plan="plan"
              @updated="reloadPlan"
            />
          </v-tab-item>
          <v-tab-item>
            <user-plan-photo-tab
              :plan="plan"
              @updated="reloadPlan"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import router from '@/router'
import { mdiEye, mdiEyeOff, mdiPencil } from '@mdi/js'
import { computed, onMounted } from '@vue/composition-api'
import EditDialog from '../../components/plan/EditDialog.vue'
import useClaimPlan from '../../composables/useClaimPlan'
import ClaimPlanItemTab from './ClaimPlanItemTab.vue'
import UserPlanAssignmentTab from './UserPlanAssignmentTab.vue'
import UserPlanDocumentTab from './UserPlanDocumentTab.vue'
import UserPlanPhotoTab from './UserPlanPhotoTab.vue'

export default {
  components: {
    ClaimPlanItemTab,
    EditDialog,
    UserPlanAssignmentTab,
    UserPlanDocumentTab,
    UserPlanPhotoTab,
  },
  setup() {
    const { loadClaimPlan, plan } = useClaimPlan()

    const reloadPlan = () => loadClaimPlan(router.currentRoute.params.id)

    onMounted(async () => {
      await reloadPlan()
    })

    const maxQuantity = computed(() => (plan.value?.rule.max_quantity > 0 ? plan.value.rule.max_quantity : 'Unlimited'))
    const maxTotal = computed(() => (plan.value?.rule.max_total > 0 ? plan.value.rule.max_total : 'Unlimited'))

    // const { tableRef, loading } = tableList

    return {
      plan,
      t,

      maxQuantity,
      maxTotal,

      reloadPlan,

      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
      },
    }
  },
}

</script>

<style>
.flex-col {
  transition: all 0.35s ease-out;
}
b+.bb-hidden {
  display: none;
}
</style>
