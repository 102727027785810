<template>
  <div>
    <h1>Admin Utility</h1>
    <p>Sync, reusable seeders, cache and MISC</p>

    <h3 class="mt-4 mb-2">
      Cache Clear
    </h3>
    <div>When you suspect the distributor results got cached, e.g. product list, category / brand list</div>
    <v-btn
      :loading="loading"
      @click="useUtility('cache-clear')"
    >
      Clear
    </v-btn>

    <h3 class="mt-4 mb-2">
      Sync Core (for a specific distributor)
    </h3>
    <div>
      When you think some distributors users in backend & core are unsync (e.g. core flushed in dev)
      <br>* Each Distributor is handled by jobs separated per 100 users. It takes ~3 seconds per 100 users.
    </div>

    <div class="d-flex mt-4">
      <v-autocomplete
        v-model="distributor_id"
        label="Distributor"
        item-text="title"
        item-value="value"
        outlined
        :items="distributorOptions"
        style="max-width: 200px;"
      ></v-autocomplete>

      <v-btn
        :loading="loading"
        class="ml-4"
        @click="useUtility('sync-core-user', { distributor_id })"
      >
        Sync
      </v-btn>
    </div>

    <h3 class="mt-4 mb-2">
      Seed Site Section Items
    </h3>
    <div>
      When Backend added new items / sections with reserved keys.
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-site')"
    >
      Seed Items
    </v-btn>

    <h3 class="mt-4 mb-2">
      Seed Site Setting
    </h3>
    <div>
      When Backend added new site setting keys
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-site-setting')"
    >
      Seed Site Setting
    </v-btn>

    <h3 class="mt-4 mb-2">
      Seed Dictionary + Sync to Core
    </h3>
    <div>
      When Backend added new dictionary reserved keys and Sync to Core.
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-dictionary')"
    >
      Seed Dictionary
    </v-btn>

    <h3 class="mt-4 mb-2">
      Seed Country from core
    </h3>
    <div>
      When core added a new country (e.g. China) on this deployment.
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-country')"
    >
      Seed Country
    </v-btn>

    <h3 class="mt-4 mb-2">
      Seed Feature
    </h3>
    <div>
      When Backend added new modules/features
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-feature')"
    >
      Seed Feature
    </v-btn>

    <h3 class="mt-4 mb-2">
      Seed User Permission
    </h3>
    <div>
      When Backend added new permission for users.
    </div>
    <v-btn
      :loading="loading"
      @click="useUtility('seed-user-permission')"
    >
      Seed User Permission
    </v-btn>

    <h3 class="mt-4 mb-2">
      Migrate a distributor
    </h3>
    <a
      href="https://mixcare-health.atlassian.net/wiki/spaces/MA/pages/229441537"
      target="_blank"
    >See Migration Docs</a> for detailed steps. You are able to:
    <ul>
      <li>Move distributors to a <u>new channel.</u></li>
      <li>Upgrade a distributor to a <u>new channel.</u></li>
    </ul>
    <v-btn
      :loading="loading"
      to="/distributor/migration-scheme-list"
      class="mr-4"
    >
      Go to Migration Page
    </v-btn>
  </div>
</template>

<script>
import { postUtility } from '@/api/utility';
import { useDistributor, useNotifyErrors, useNotifySuccess } from '@/composables';
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const loading = ref(false)
    const distributor_id = ref(undefined)

    const useUtility = async (route, params = {}) => {
      loading.value = true

      await postUtility(route, params)
        .then(() => useNotifySuccess({ content: route }))
        .catch(useNotifyErrors)
        .finally(() => {
          loading.value = false
        })
    }

    const { distributorOptions } = useDistributor()

    return {
      useUtility,
      loading,
      distributor_id,
      distributorOptions,
    }
  },
}
</script>
