var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5 d-flex justify-space-between"
  }, [_vm._t("title", function () {
    return [_vm._v(" Participant ")];
  }), _c('v-icon', {
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 2), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "*First Name",
      "rules": [_vm.validators.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "first_name", $$v);
      },
      expression: "form.first_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "*Last Name",
      "rules": [_vm.validators.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "last_name", $$v);
      },
      expression: "form.last_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "*Email",
      "rules": [_vm.validators.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "*Phone",
      "rules": [_vm.validators.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [{
        value: 'male',
        text: 'Male'
      }, {
        value: 'female',
        text: 'Female'
      }],
      "label": "*Gender",
      "rules": [_vm.validators.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "ID No.",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.id_no,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "id_no", $$v);
      },
      expression: "form.id_no"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Employee ID",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.employee_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "employee_id", $$v);
      },
      expression: "form.employee_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "Birthday",
            "outlined": "",
            "dense": "",
            "readonly": ""
          },
          model: {
            value: _vm.form.birthday,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "birthday", $$v);
            },
            expression: "form.birthday"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.birthdayMenu,
      callback: function callback($$v) {
        _vm.birthdayMenu = $$v;
      },
      expression: "birthdayMenu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": ""
    },
    on: {
      "input": function input($event) {
        _vm.birthdayMenu = false;
      }
    },
    model: {
      value: _vm.form.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "*Relationship",
      "outlined": "",
      "items": _vm.relationshipOptions,
      "item-text": "title",
      "item-value": "value",
      "rules": [_vm.validators.required]
    },
    model: {
      value: _vm.form.relationship,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "relationship", $$v);
      },
      expression: "form.relationship"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Default Always Reusable",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.form.reusable,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reusable", $$v);
      },
      expression: "form.reusable"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")]), _c('p', [_vm._v("Just make sure if you intend to use this particiapnt for medical card, some fields are required.")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }