<template>
  <v-select
    v-model="planPassId"
    placeholder="Select Plan Pass"
    :items="planPassOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    clearable
    :hide-details="hideDetails"
    :disabled="disabled"
    :dense="dense"
  >
  </v-select>
</template>
<script>
import { usePlanPass } from '@/composables';
import { ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    distributorId: {
      type: [Number, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const { planPassOptions, fetchPlanPasses } = usePlanPass()

    const planPassId = ref(props.filters.plan_pass_id)

    const distributor_id = props.distributorId
    const take = 999

    fetchPlanPasses({ distributor_id, take })

    watch(planPassId, value => {
      emit('changed', {
        ...props.filters,
        plan_pass_id: value,
      })
    })

    return {
      planPassOptions,
      planPassId,
    }
  },
}
</script>
