<template>
  <v-data-table
    :headers="columns"
    :items="items"
    :options.sync="options"
    :server-items-length="itemsTotal"
    :loading="loading"
    class="table-section text-no-wrap"
  >
    <!-- ID -->
    <template #[`item.id`]="{ item }">
      #{{ item.id }}
    </template>

    <!-- Email -->
    <template #[`item.email`]="{ item }">
      {{ item.name }} <br>
      <router-link :to="{ name: 'user-overview', params: { id: item.id } }">
        {{ item.email }}
      </router-link>
    </template>

    <!-- Participants -->
    <template #[`item.participants`]="{ item }">
      <assign-to-user-participant
        :plan="plan"
        :user="item"
        @updated="paginate"
      />
    </template>

    <!-- Plans -->
    <template #[`item.plans`]="{ item }">
      <div
        v-for="owner in item.claim_plan_owners"
        :key="owner.id"
      >
        <ul>
          <li>
            {{ t(owner.claim_plan.name) }}

            <v-icon
              size="18"
              @click="confirmDeleteOwner(owner).then(paginate)"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </li>
          <li
            v-for="participant in owner.participants"
            :key="participant.participant_uuid"
            class="ml-4"
          >
            {{ findParticipantName(participant.participant_uuid) }}
            <v-icon
              size="18"
              @click="confirmDeleteParticipant(participant).then(paginate)"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </li>
        </ul>
      </div>
    </template>

    <!-- date -->
    <template #[`item.created_at`]="{ item }">
      <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
    </template>

    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-center">
        <!-- dropdown -->
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
            >
              <v-list-item-title>
                <!-- <edit-dialog
                    :user="item"
                    @updated="onUserUpdated"
                  /> -->
                Test
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { useParticipant } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical, mdiTrashCan } from '@mdi/js'
import AssignToUserParticipant from './AssignToUserParticipant.vue'

export default {
  components: { AssignToUserParticipant },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { findParticipantName } = useParticipant()

    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'User', value: 'email' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Participants', value: 'participants', sortable: false },
      { text: 'Plans', value: 'plans', sortable: false },

      // { text: 'ACTIONS', value: 'actions', sortable: false },
    ]

    return {
      t,
      formatDate,
      columns,
      findParticipantName,

      loading,
      options,
      paginate,
      items,
      itemsTotal,
      headerprops,

      icons: {
        mdiDotsVertical,
        mdiTrashCan,
      },
    }
  },
}
</script>
