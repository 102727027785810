var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "color": item.active ? 'success' : 'error',
            "text-color": "white"
          }
        }, [_vm._v(" " + _vm._s(item.active ? 'Active' : 'Inactive') + " ")])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'claim-plan-view',
              params: {
                id: item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.t(item.name)) + " ")])];
      }
    }, {
      key: "item.reward_points",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.reward_points > 0 ? item.reward_points : '-') + " ")];
      }
    }, {
      key: "item.distributor_id",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.distributor.nicename) + " ")];
      }
    }, {
      key: "item.plan_pass_id",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.plan_pass ? _c('span', [_vm._v(" Associated Pass: "), _c('router-link', {
          attrs: {
            "to": {
              name: 'pass-list',
              query: {
                search: item.plan_pass.title.en
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.plan_pass.title.en) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: "item.effective_date",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.effective_date)) + " - " + _vm._s(_vm.formatDate(item.expires_at)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on,
                attrs = _ref9.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_vm.$can('claim_plan:view', 'claim_plan_management') ? _c('v-list-item', {
          attrs: {
            "link": "",
            "to": {
              name: 'claim-plan-view',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiEyeOutline) + " ")]), _c('span', [_vm._v("View & Items")])], 1)], 1) : _vm._e(), _vm.$can('claim_plan:delete', 'claim_plan_management') ? _c('v-list-item', {
          on: {
            "click": function click($event) {
              _vm.confirmDeletePlan(item).then(_vm.paginate);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1) : _vm._e()], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }